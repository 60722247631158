* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  width: 100%;
}
td, th {
  border: 1px solid #ddd;
  padding: .5rem;
}

.Cart button.cart-remove {
  padding: .25rem;
  background-color: rgb(161, 15, 15);
  color: white;
  display: inline-block;
  width: 2rem;
}

/* td:nth-child(3), td:nth-child(4) { */
/* th:nth-last-child(1), th:nth-last-child(2),
td:nth-last-child(1), td:nth-last-child(2) {
  text-align: right;
} */
.Cart th:nth-last-child(1), .Cart td:nth-last-child(1) {
  text-align: right;
}
.Cart tr:nth-last-child(3), .Cart tr:nth-last-child(2), .Cart tr:nth-last-child(1) {
  text-align: right;
}

.Orders th:nth-last-child(1), .Orders th:nth-last-child(3),
.Orders td:nth-last-child(1), .Orders td:nth-last-child(3) {
  text-align: center;
}

.Orders th:nth-child(1),
.Orders td:nth-child(1) {
  text-align: center;
}
.Orders th:nth-last-child(2),
.Orders td:nth-last-child(2) {
  text-align: right;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: left;
  background-color: gold;
  color: #333;
}

footer {
  padding: 2rem;
  background-color: black;
  color: #fff;
  display: flex;
  /* justify-content: space-around; */
  color: white;
}

.Shopping-status {
  display: flex;
  flex-direction: row-reverse;
  color: white;
}
.Shopping-status span {
  /* padding: 1rem; */
  margin-left: .5rem;
}

.Product-hook {
  padding: 2rem;
  /* overflow: none; */
  border: .2rem solid gold;
  margin-bottom: .25rem;
 
}
.Product-hook img {
  /* display: block; */
  width: 100%;
}

.Product-hook button {
  display: block;
  padding: 1rem 2rem;
  margin: 1rem auto;
  background-color: #333;
  border: none;
  border-radius: 1rem;
}

h1 {
  color: #333;
}
h2 {
  color: #333;
  font-size: .9rem;
}
h3 {
  font-size: .9rem;
  color: #333;
}

.Cart button {
  width: 100%;
  padding: .75rem 0;
  border-radius: .5rem;
  border: none;
  font-size: 1.75rem;
  /* font-family: 'Tangerine', cursive; */
  color: #f3cd14;
  background-color: #333;
  /* font-size: 3rem; */
  /* text-shadow: 4px 4px 4px #e4e635; */
}
.Cart tfoot button {
  width: initial;
  margin-right: .5rem;
  padding: .75rem 1rem;
  border-radius: .5rem;
  border: none;
  font-size: 1em;
}


.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}



header {
  padding: 1rem;
  background-color: black;
}

header h1 {
  text-align: center;
  padding: 1rem;
  color: #f3cd14;
  font-family: 'Tangerine', cursive;
  font-size: 3rem;
  text-shadow: 4px 4px 4px #e4e635;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Cart, .Orders, .Payment, .Shipping, .App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Cart .container, .Orders .container, .Payment .container, .Shipping .container, .App .container {
  /* height: 100vh; */
  padding: .25rem;
}

input {
  padding: .5rem;
  border-radius: .25rem;
  border: 1px solid #555;
}


ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
li a {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: .5rem 0;
  border-radius: .5rem;
}
li a:hover {
  background-color: #555;
  color: white;
}
.active {
  background-color: #4CAF50;
  color: white;
}

.tupperware {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.tupperware input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #aaa;
  border-radius: 50%;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #aaa;
}

/* the .checkmark class sibling following an input */
.tupperware:hover input ~ .checkmark, .tupperware:hover input ~ .radio {
  background-color: #ccc;
}

/* the .radio class sibling following an input:checked */
.tupperware input:checked ~ .checkmark, .tupperware input:checked ~ .radio {
  background-color: #333;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after, .radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.tupperware input:checked ~ .checkmark:after, .tupperware input:checked ~ .radio:after {
  display: block;
}


/* radio  */
.tupperware .radio:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: gold;
}



/* checkbox */
.tupperware .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid gold;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select {
  padding: .5rem;
  border-radius: .25rem;
  border: 1px solid #555;
  background-color: #fff;
}